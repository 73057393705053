import React, { createContext, useContext, useState, useEffect } from "react";
import { io } from "socket.io-client";

// Create the SocketContext
const SocketContext = createContext();

// Socket Provider Component
export const SocketProvider = ({ children }) => {
  const [sockets, setSockets] = useState({
    dashboard: null, // Dashboard socket instance
    data: null, // Data socket instance
  });

  // Function to connect to a specific socket
  const connectSocket = ({ token, path, auto, category }) => {
    if (!token || !path || !category) {
      console.error("Invalid parameters for socket connection");
      return;
    }

    if (sockets[category]) {
      console.log(`Socket for ${category} is already connected`);
      return sockets[category];
    }

    // Create a new socket instance
    const newSocket = io(process.env.REACT_APP_API_SOCKETIO_ENDPOINT, {
      autoConnect: auto,
      path: path,
      upgrade: false,
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      query: { token },
      transports: ["websocket"],
      rejectUnauthorized: false,
    });

    // Attach event listeners
    newSocket.on("connect", () => {
      console.log(`Connected to ${category} socket`);
    });

    newSocket.on("connect_error", (err) => {
      console.error(`Socket connection error (${category}):`, err);
    });

    newSocket.on("disconnect", (reason) => {
      console.log(`Disconnected from ${category} socket:`, reason);
    });

    newSocket.on("error", (err) => {
      console.error(`Socket error (${category}):`, err);
    });

    // Store the socket in the state
    setSockets((prevSockets) => ({
      ...prevSockets,
      [category]: newSocket,
    }));

    // Store socket details in sessionStorage for reconnecting after refresh
    const savedSockets = JSON.parse(sessionStorage.getItem("sockets")) || {};
    savedSockets[category] = { token, path, auto, category };
    sessionStorage.setItem("sockets", JSON.stringify(savedSockets));

    return newSocket;
  };

  // Disconnect all sockets (optional for cleanup)
  const disconnectAllSockets = () => {
    Object.values(sockets).forEach((socket) => {
      if (socket) {
        socket.disconnect();
      }
    });
    setSockets({ dashboard: null, data: null });
    sessionStorage.removeItem("sockets"); // Clear persisted sockets
  };

  // Reconnect sockets on page load
  useEffect(() => {
    const savedSockets = JSON.parse(sessionStorage.getItem("sockets"));
    if (savedSockets) {
      Object.values(savedSockets).forEach(({ token, path, auto, category }) => {
        connectSocket({ token, path, auto, category });
      });
    }
  }); // Runs only once when the provider initializes

  return (
    <SocketContext.Provider
      value={{ sockets, connectSocket, disconnectAllSockets }}>
      {children}
    </SocketContext.Provider>
  );
};

// Hook to use the SocketContext
export const useSocket = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error("useSocket must be used within a SocketProvider");
  }
  return context;
};
