import React, { useMemo, useState } from 'react'
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Zoom, Legend, DateTime, Tooltip, DataLabel, ScrollBar, SplineSeries, Highlight } from '@syncfusion/ej2-react-charts';


const transformChartData = (features, selectedDate) => {
  const dataMap = {
    4: [], // Power
    2: [], // Voltage
    3: [], // Current
  };

  features?.forEach((feature) => {
    const { featureId, data } = feature;
    if (data && Array.isArray(data)) {
      const filteredData = data
        .filter((entry) => {
          const entryDate = new Date(entry?.AADATETIME).toISOString().split("T")[0];
          return entryDate === selectedDate;
        })
        .sort((a, b) => new Date(a.AADATETIME) - new Date(b.AADATETIME));

      filteredData.forEach((entry) => {
        const xValue = new Date(entry?.AADATETIME);
        const yValue = entry?.AAVALUE1 ?? 0;
        dataMap[featureId]?.push({ x: xValue, y: yValue });
      });
    }
  });

  return dataMap;
};

const Chart001 = ({ widgetData }) => {
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split("T")[0]);

  const chartData = useMemo(() => {
    const features = widgetData?.components?.[0]?.features || [];
    return transformChartData(features, selectedDate);
  }, [widgetData, selectedDate]);

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const zoomsettings = {
      enableMouseWheelZooming: true,
      enablePinchZooming: true,
      enableSelectionZooming: true,
      mode: 'X',
      enableScrollbar: true,
  };
  const primaryxAxis = { 
    zoomFactor: 0.05,
    valueType: 'DateTime',
    labelFormat: 'hh:mm a',
    intervalType: 'Minutes',
    edgeLabelPlacement: 'Shift',
    majorGridLines: { width: 0 },
    labelIntersectAction: "Rotate45",
    labelStyle: { size: "10px" },
  };

  const primaryyAxis = {
    labelFormat: "{value}",
    minimum: 0,
    maximum: 1500,
    lineStyle: { width: 0 },
    majorTickLines: { width: 0 },
    minorTickLines: { width: 0 },
  }

  const load = (args) => {
      args.chart.zoomModule.isZoomed = true;
  };

  return (
  <div className="chart-container h-[500px] w-full">
      <div className="absolute left-2 top-2 z-[9999]">
        <input
          id="datePicker"
          type="date"
          value={selectedDate}
          onChange={handleDateChange}
          className="border rounded px-2 py-1"
        />
      </div>
    <div className='py-4 responsive-chart'>
      <ChartComponent id='charts' ref={chart => chart} primaryXAxis={primaryxAxis} primaryYAxis={primaryyAxis} zoomSettings={zoomsettings} title='Power, Voltage, Current Report' load={load} tooltip={{ enable: true }}
          legendSettings={{ enableHighlight: true }}>
        <Inject services={[SplineSeries, Legend, Highlight, Tooltip, DataLabel, DateTime, ScrollBar, Zoom]}/>
        <SeriesCollectionDirective>
          <SeriesDirective
            dataSource={chartData[4]}
            xName="x"
            yName="y"
            width={2}
            name="Power"
            type="Spline"
            marker={{ visible: true, width: 7, height: 7, isFilled: true }}
          />
          <SeriesDirective
            dataSource={chartData[2]}
            xName="x"
            yName="y"
            width={2}
            name="Voltage"
            type="Spline"
            marker={{ visible: true, width: 7, height: 7, isFilled: true }}
          />
          <SeriesDirective
            dataSource={chartData[3]}
            xName="x"
            yName="y"
            width={2}
            name="Current"
            type="Spline"
            marker={{ visible: true, width: 7, height: 7, isFilled: true }}
          />
        </SeriesCollectionDirective>
      </ChartComponent>
    </div>
  </div>
  )  
};


export default Chart001