import React from 'react'
import { SettingOutlined } from '@ant-design/icons';

const SideButton = ({ collapsed, setCollapsed }) => {
  return (
    <button onClick={() => setCollapsed(prevState => !prevState)} className={`flex items-center justify-center fixed ${collapsed ? 'right-[-5px]' : 'hidden'} top-20 rounded-tl-md rounded-bl-md p-2 bg-[var(--primary-color)] text-white z-[9998]`}>
        <SettingOutlined />
    </button>
  )
}

export default SideButton