import { createSlice } from "@reduxjs/toolkit";
// import Cookies from "universal-cookie";

// const cookies = new Cookies();

const authSlice = createSlice({
  name: "auth",
  initialState: {
    userId: localStorage.getItem("userId") || null,
    token: localStorage.getItem("_token") || null,
    userData: JSON.parse(localStorage.getItem("userData")) || null,
  },
  reducers: {
    setCredentials: (state, action) => {
      const { userId, accessToken, userData } = action.payload;
      state.userId = userId;
      state.token = accessToken;
      state.userData = userData;
    },
    logOut: (state) => {
      state.userId = null;
      state.token = null;
      state.userMenu = null;
      localStorage.removeItem("_token");
      localStorage.removeItem("userId");
      localStorage.removeItem("userPages");
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.userId;
export const selectCurrentToken = (state) => state.auth.token;
export const getUserData = (state) => state.auth.userData;
