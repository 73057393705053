import React from 'react'
import EditButton from './EditButton'
import ChooseDashboard from './ChooseDashboard'
import Timer from './Timer'

const DashSide = ({ collapsed, setCollapsed }) => {

  return (
    <div className={`${collapsed ? 'hidden' : 'fixed w-[320px] h-screen right-0'} border shadow-lg z-50 bg-white px-2 py-2`}>
      <div>
        <div className='flex justify-between items-center'>
          <h1 className='text-gray-800'>Dashboard Customizer</h1>
          <button onClick={() => setCollapsed(prevState => !prevState)}>x</button>
        </div>
        <p className='text-gray-600 text-[12px] font-light'>Customize and preview your dashboard in real time</p>
        <hr className='my-4'/>
      </div>
        <EditButton />
        <ChooseDashboard />
        <Timer />  
    </div>
  )
}

export default DashSide