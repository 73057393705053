import React, { useEffect, useState } from 'react';
import { Button, Radio } from 'antd';
import { useGlobalContexts } from '../../../features/contexts/GlobalContexts';

const ChooseDashboard = () => {
  const { allDashboards, selectedDashboard, setSelectedDashboard } = useGlobalContexts();
  const [selectedOption, setSelectedOption] = useState('Loading...');
  const [showDashs, setShowDashs] = useState(false)
  const [loading, setLoading] = useState(true); // New state to track loading

  useEffect(() => {
    if (allDashboards?.length > 0) {
      setSelectedDashboard(allDashboards[0]); // Initialize with the first dashboard
      setSelectedOption(allDashboards[0]); // Update the display
      setLoading(false); // Set loading to false once data is loaded
    }
  }, [allDashboards, setSelectedDashboard]);

  const handleDashboardSelection = (dashboardName) => {
    setSelectedDashboard(dashboardName); // Update context
    setSelectedOption(dashboardName); // Update displayed option
  };

  const menuItems = allDashboards?.map((dashboard, index) => (
      <div
        key={index}
        onClick={() => handleDashboardSelection(dashboard)}
        className="flex items-center gap-1 cursor-pointer hover:bg-gray-100 p-2 rounded-md"
      >
        <Radio checked={selectedDashboard === dashboard} />
        <span className="text-sm">{dashboard}</span>
      </div>
    ),
  );

  return (
    <div className='mt-2'> 
      <Button onClick={() => setShowDashs(prevState => !prevState)} disabled={loading}>
        {selectedOption}
      </Button>
      <div className={`transition-all duration-150 ease-in-out ${showDashs ? 'block' : 'hidden'}`}>
        {menuItems}
      </div>
    </div>
  );
};

export default ChooseDashboard;
