import './App.css'
import {Routes, Route, Navigate} from 'react-router-dom'
import Layout from './components/Layout'
import Home from './pages/Home'
import Login from './pages/Login'
import ForgotPassword from './components/Login/ForgotPassword'
import RequireAuth from './components/RequireAuth'
import { useSelector } from 'react-redux'
import { selectCurrentToken } from './features/auth/authSlice'
import ChangePassword from './components/Login/ChangePassword'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from './components/Dashboard/Dashboard'
import Chart001 from './components/Dashboard/Widgets/Chart001'


const App = () => {
  const token = useSelector(selectCurrentToken);

  return (
    <div>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* Public Routes */}
          <Route index element={!token ? <Login /> : <Navigate to='/home' />} />
          <Route path='/login' element={!token ? <Login /> : <Navigate to="/home"/>}/>
          <Route path='/forgotpass' element={<ForgotPassword />}/>
          <Route path='/changepass' element={<ChangePassword />}/>
          

          {/* Protected Routes */}
          <Route element={<RequireAuth />}>
            <Route path='/home' element={<Home />}>
              {/* Redirect /home to /home/userdash001 */}
              <Route index element={<Navigate to='userdash001' />} />

              {/* Main Dashboard Routes */}
              <Route path='userdash001' element={<Dashboard />} />
              <Route path='userdata001' element={<Chart001 />} />

              {/* Notification Routes */}
              <Route path='defaultPage/*' element={<Dashboard />}>
                <Route path='tickets001' element={<Dashboard />} />
                <Route path='tickets002' element={<Dashboard />} />
              </Route>

              {/* Other Routes */}
              <Route path='defaultPage/*' element={<Dashboard />}>
                <Route path='logs001' element={<Dashboard />} />
                <Route path='alerts001' element={<Dashboard />} />
              </Route>

              <Route path='usercontrol001' element={<Dashboard />}/>
              <Route path='usermonitor001' element={<Dashboard />}/>
            </Route>
          </Route>
        </Route>
      </Routes>
    </div>
  )
}

export default App
