import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { AiOutlineRight, AiOutlineLeft, AiOutlineDown } from "react-icons/ai";
import taqalogo from '../../../assets/TE.png';
// import { useGlobalContexts } from '../../../features/contexts/GlobalContexts';

const Sidebar = ({ userPages, updateTitle }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [activeSections, setActiveSections] = useState({});
  // const { allDashboards, selectedDashboard, setSelectedDashboard } = useGlobalContexts();

  // Function to toggle active state of a section
  const toggleSection = (id) => {
    setActiveSections((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  // Group pages by category
  const groupedPages = userPages.reduce((acc, page) => {
    (acc[page.pageCategory] = acc[page.pageCategory] || []).push(page);
    return acc;
  }, {});

  return (
    <div className="relative border-r border-slate-200 shadow-lg z-30">
      <div className={`${collapsed ? 'w-[80px]' : 'w-[260px]'}`}></div>
      <div
        className={`${collapsed ? 'w-[80px]' : 'w-[260px]'} fixed transition-all duration-150 ease-in-out`}
      >
        <div>
          {/* Logo container */}
          <div className="m-3">
            <img
              src={taqalogo}
              width={124}
              alt="Taqa Logo"
              className={`${collapsed ? 'hidden' : 'block'} transition-all duration-150 ease-in-out`}
            />
            <button
              className="z-40 p-[3px] rounded-full absolute right-[-10px] top-6 shadow-lg bg-[var(--primary-color)] flex justify-center items-center"
              onClick={() => setCollapsed((prevState) => !prevState)}
            >
              {collapsed ? <AiOutlineRight size={14} color="white" /> : <AiOutlineLeft size={14} color="white" />}
            </button>
          </div>

          {/* Navigation Items */}
          <div className={`${collapsed ? 'my-12' : 'my-6'} sidebar-container h-[calc(100vh-100px)]`}>
            {Object.keys(groupedPages).map((category, idx) => (
              <div key={idx}>
                {/* Category Title */}
                <div className="text-gray-400 text-sm px-4 py-2">
                  {collapsed ? <hr /> : category || ''}
                </div>
                {groupedPages[category].map((page) => (
                  <div key={page.pageId}>
                    {/* Main navigation item */}
                    <NavLink
                      to={`/home/${page.pageRoute}`} // Match routes dynamically
                      className={({ isActive }) =>
                        `flex items-center gap-4 px-2 py-2 my-1 rounded-lg cursor-pointer transition-all duration-150 ease-in-out ${
                          isActive 
                            ? 'bg-[var(--primary-color)] text-white'
                            : 'hover:bg-gray-200 text-gray-700'
                        } ${collapsed ? 'justify-center mx-4' : 'mx-2'}`
                      }
                      onClick={() => {
                        toggleSection(page.pageId);
                        updateTitle(page.pageTitle);
                      }}
                    >
                      <img
                        src={page.pageIcon}
                        alt={`${page.pageTitle} icon`}
                        className="w-5 h-5"
                      />
                      <span
                        className={`${collapsed ? 'hidden' : 'block'} transition-all duration-150 ease-in-out flex justify-between items-center w-full`}
                      >
                        {page.pageTitle}
                        {page.pageSections && page.pageSections.length > 0 && (
                          activeSections[page.pageId] ? <AiOutlineDown size={12} /> : <AiOutlineRight size={12} />
                        )}
                      </span>
                    </NavLink>

                    {/* Child navigation items (if any) */}
                    {page.pageSections && (
                      <div
                        className={`ml-[6px] transition-all duration-150 ease-in-out ${
                          activeSections[page.pageId] ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'
                        } ${collapsed ? 'hidden' : 'block'}`}
                        style={{
                          overflow: 'hidden', // Ensures smooth height transitions
                        }}
                      >
                        {page.pageSections.map((section) => (
                          <div key={section.sectionId}>
                            {section.sectionApps.map((app) => (
                              <NavLink
                                to={`/home/${page.pageRoute}/${app.appRoute}`} // Match child routes dynamically
                                key={app.appId}
                                className={({ isActive }) =>
                                  `flex items-center gap-7 py-2 my-1 w-full hover:text-[var(--primary-color)] cursor-pointer transition-colors duration-150 ease-in-out ml-4 text-sm ${
                                    isActive ? 'text-[var(--primary-color)]' : 'text-gray-500'
                                  }`
                                }
                              >
                                <span
                                  className="w-[6px] h-[6px] bg-[var(--primary-color)] rounded-full shadow-[0_0_10px_3px_rgba(59,130,246,0.6)]"
                                ></span>
                                <span>{app.appTitle}</span>
                              </NavLink>
                            ))}
                          </div>
                        ))}
                      </div>
                    )}

                    {/* Dashboard children */}
                    {/* {page.pageTitle === 'Dashboard' && allDashboards && (
                      <div
                        className={`ml-[6px] transition-all duration-150 ease-in-out ${
                          activeSections[page.pageId] ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'
                        } ${collapsed ? 'hidden' : 'block'}`}
                        style={{
                          overflow: 'hidden', // Ensures smooth height transitions
                        }}
                      >
                        {allDashboards.map((dashboard, index) => (
                          <div
                            key={index}
                            className={`flex items-center gap-7 py-2 my-1 w-full hover:text-[var(--primary-color)] cursor-pointer transition-colors duration-150 ease-in-out ml-4 text-sm ${selectedDashboard === dashboard ? 'text-[var(--primary-color)]': 'text-gray-500'}`}
                            onClick={() => setSelectedDashboard(dashboard)}       
                          >
                            <span className="w-[6px] h-[6px] bg-[var(--primary-color)] rounded-full shadow-[0_0_10px_3px_rgba(59,130,246,0.6)]"></span>
                            <span>{dashboard}</span>
                          </div>
                        ))}
                      </div>
                    )} */}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
