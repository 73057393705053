import React from 'react'
import '../styles/checkbox.css'
import '../styles/input.css'

const Input = ({ className, type, placeholder, value, id, name, checked, handleChange }) => {
    const classes = `${className || ''} input absolute z-50 rounded-sm mt-4 border border-gray-300 w-full p-2 focus:outline-none focus:border-[var(--primary-color)] text-sm bg-transparent transition ease duration-200 text-[var(--primary-color)]`

    const renderInput = () => (
        <div className='relative h-[60px] mb-2'>
            <input className={classes} type={type} value={value} id={id} name={name} onChange={handleChange} autoComplete='off' required />  
            <label className='label absolute text-sm text-gray-500 transition ease duration-200 bg-transparent mt-6 px-2' htmlFor={id}>{placeholder}</label>
        </div>  
    )

    const renderCheckbox = () => (
        <div className='flex items-center gap-1'>
            <input className='w-3 h-3' type={type} value={value} id={id} name={name} checked={checked} onChange={handleChange} />  
            <label className='text-xs text-[var(--primary-color)]' htmlFor={id}>{placeholder}</label>
        </div> 
    )

    return type === 'checkbox' ? renderCheckbox() : renderInput()
}

export default Input