import React from 'react';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { enableRipple } from '@syncfusion/ej2-base';
import { useGlobalContexts } from '../../../features/contexts/GlobalContexts';

enableRipple(true);

const Timer = () => {
    const { setSelectedTime } = useGlobalContexts();
  // Options from 1 minute to 10 minutes
  const timerOptions = Array.from({ length: 10 }, (_, i) => ({
    value: i + 1,
    text: `${i + 1} minute${i > 0 ? 's' : ''}`,
  }));


  // Handle change
  const handleChange = (event) => {
    setSelectedTime(event.value);
    console.log(`Selected time: ${event.value} minutes`);
  };

  return (
    <div className="w-1/2 mt-4">
      <DropDownListComponent
        id="timer-dropdown"
        dataSource={timerOptions}
        fields={{ text: 'text', value: 'value' }}
        placeholder="Request Value Timer"
        popupHeight="200px"
        change={handleChange}
      />
    </div>
  );
};

export default Timer;
