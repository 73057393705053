import React, { useState } from 'react'

import Input from '../../ui/Input'
import Button from '../../ui/Button'

import { useApirequestMutation } from '../../features/auth/authApiSlice'
import { HandleForgotPass } from '../../features/utils/Handlers'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'


const ForgotPassword = () => {
    const dispatch = useDispatch()
    const [apirequest, { isLoading }] = useApirequestMutation()
    const navigate = useNavigate()
    
    const [user, setUser] = useState('')

    const handleSubmit = async (e) => {
      e.preventDefault()
      await HandleForgotPass({user, setUser, dispatch, apirequest, navigate})  
    }
    
    const handleUserInput = (e) => setUser(e.target.value)

    return (
        <div className="flex justify-center items-center m-0 h-screen bg-slate-100">
            <div className="container w-[30rem] h-[50vh] boxshadow border border-neutral-200 rounded-sm p-4 bg-white">
              <h1 className='h1 mb-6 text-center'>Forgot Your Password?</h1>
              <p className='text-gray-500 text-center mb-2'>Please enter your username or email to receive an email to reset your password.</p>
              
              {/* Form for sending email/username to change password */}
              <form onSubmit={handleSubmit} className='mt-6'>
                <Input placeholder='Username or Email' type='text' value={user} handleChange={handleUserInput}/>
                <div className="flex justify-between items-center mt-6">
                  <Button type="submit" buttonText={`${isLoading ? 'Loading...' : 'Send Email'}`} className="button"/>
                  <Button path="/" buttonText="Go Back" className="transition ease duration-300 hover:text-[var(--primary-color)]"/>
                </div>
              </form>
            </div>
        </div>
      )
}

export default ForgotPassword