import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";

const BoxC001 = ({ widget, widgetData }) => {
    const { title, description, component } = widget;

    const formatDate = (dateString) => {
        if (!dateString) return { formattedTime: "N/A", fullDate: "N/A" };

        // Parse the date as UTC
        const date = new Date(dateString);

        // Extract hours and minutes in UTC
        let hours = date.getUTCHours();
        const minutes = date.getUTCMinutes().toString().padStart(2, "0");

        // Determine AM or PM
        const amPm = hours >= 12 ? "PM" : "AM";

        // Convert to 12-hour format
        hours = hours % 12 || 12;

        // Extract day, month, and year
        const day = date.getUTCDate().toString().padStart(2, "0");
        const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
        const year = date.getUTCFullYear();

        // Construct full date in DD/MM/YYYY format
        const fullDate = `${day}/${month}/${year}`;

        // Return formatted time and full date
        return { formattedTime: `${hours}:${minutes} ${amPm}`, fullDate };
    };

    return (
        <div className="flex flex-col h-full w-full px-2 py-1">
            {/* Widget Title */}
            <div className="flex flex-col">
                <h3 className="text-lg font-semibold text-[var(--primary-color)]">{title}</h3>
                <span className="text-[10px] text-gray-500">{description}</span>
            </div>

            <hr className="w-full my-1" />

            {/* Component Details */}
            <div>
                {component.map((comp, index) => (
                    <div key={index} className="mb-2">
                        <div className="flex justify-between items-center w-full">
                            {/* Tooltip */}
                            <div className="flex items-center gap-1">
                                <div className="relative group">
                                    <span className="text-[var(--primary-color)] cursor-pointer">
                                        <AiOutlineInfoCircle size={16} />
                                    </span>
                                    <div className="absolute left-0 z-[9999] hidden w-40 p-2 mt-2 text-sm text-white bg-gray-200 rounded-md shadow-lg group-hover:block">
                                        <p className="font-semibold text-xs text-[var(--primary-color)]">{comp.componentDetails.componentDesc}</p>
                                        <p className="text-xs text-[var(--primary-color)]">{`Location 1: ${comp.componentDetails.componentLocation1}`}</p>
                                        <p className="text-xs text-[var(--primary-color)]">{`Location 2: ${comp.componentDetails.componentLocation2}`}</p>
                                    </div>
                                </div>

                                {/* Component Name */}
                                <h4 className="text-md">{comp.componentDetails.componentName}</h4>
                            </div>
                        </div>

                        <div className="flex flex-col gap-1 mt-2">
                            {comp.features.map((feature, idx) => {
                                // Find the matching data for the current component and feature
                                const componentData = widgetData?.components?.find(
                                    (dataComp) => dataComp.componentId === comp.componentDetails.componentId
                                );

                                const featureData = componentData?.features?.find(
                                    (dataFeature) => dataFeature.featureId === feature.featureDetails.featureId
                                );

                                const value = featureData?.data?.[0]?.AAVALUE1 || "N/A";
                                const date = featureData?.data?.[0]?.AADATETIME || "N/A";
                                const { formattedTime, fullDate } = formatDate(date);

                                return (
                                    <div key={idx} className="text-sm text-gray-800 flex gap-2">
                                        <span className="text-sm">{feature.featureDetails.featureDesc}:</span>
                                        <div className="flex items-center justify-between w-full">
                                            <div className="flex gap-1">
                                                <span className="text-sm">{value}</span>
                                                <span className="text-sm font-semibold">{feature.featureDetails.featureUnit}</span>
                                            </div>
                                            <div className="relative group">
                                                <span className="text-[10px] text-[var(--primary-color)] cursor-pointer">
                                                    {formattedTime}
                                                </span>
                                                <div className="absolute left-0 top-3 z-[9999] hidden text-[var(--primary-color)] group-hover:block">
                                                    <span className="text-[8px]">{fullDate}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        <hr className="w-full mt-2" />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BoxC001;
