import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { useApirequestMutation } from '../../features/auth/authApiSlice'
import { getResetInfo } from '../../features/auth/resetPassSlice'
import { HandleChangePass } from '../../features/utils/Handlers'

import Input from '../../ui/Input'
import Button from '../../ui/Button'
import { OtpInputComponent } from '@syncfusion/ej2-react-inputs';
import '../../styles/input.css'


const ChangePassword = () => {
    const navigate = useNavigate()

    const resetInfo = useSelector(getResetInfo)
    const [otp, setOtp] = useState()
    const [pass, setPass] = useState('')
    const [confirmPass, setConfirmPass] = useState('')

    // States for handling password visibility
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(false);

    const [apirequest, { isLoading }] = useApirequestMutation()

    const handleSubmit = async (e) => {
      e.preventDefault()
      await HandleChangePass({otp, pass, setPass, setConfirmPass, resetInfo, apirequest, navigate})
    }

    const handleToggle = () => {
      if (type === 'password'){
          setIcon(true);
          setType('text')
      } else {
          setIcon(false)
          setType('password')
      }
     }

    const checkMismatch = () => {
      if (!otp || !pass || !confirmPass) return false; // Ensure all fields are filled
      if (pass !== confirmPass) {
        return false; // Passwords don't match
      }
      return true; // Everything is valid
    };

    function OtpValueChange(args) {
        setOtp(args.value);
    }

    const handlePassInput = (e) => setPass(e.target.value)
    const handleConfirmPassInput = (e) => setConfirmPass(e.target.value)

    useEffect(() => {
      if (!resetInfo || resetInfo?.message?.type === 'error') {
        navigate('/forgotpass');
      }
    }, [resetInfo, navigate]);

    return (
        <div className="flex justify-center items-center m-0 h-screen bg-slate-100">
          <div className="flex flex-col justify-center items-center h-full">
            <div className="container w-[30rem] boxshadow border border-neutral-200 rounded-sm p-4 bg-white">
              <h1 className='h1 mb-4 text-center'>Change Your Password</h1>
              <p className='text-gray-500 text-center mb-4'>Please enter the code you received in your email below.</p>
               
              {/* Form for changing password */} 
              <form onSubmit={handleSubmit}>
                <OtpInputComponent className='otp' id="numberOtp" value={otp} type='number' valueChanged={OtpValueChange} />
                
                {/*  */}
                <div className='relative mt-4'>
                    <Input placeholder='Password' type={type} value={pass} handleChange={handlePassInput}/>
                    <i className={`${icon ? 'bx bx-show' : 'bx bx-hide'} absolute z-[1000] right-2 top-[1.6rem] cursor-pointer text-[var(--primary-color)]`} onClick={handleToggle}/>
                </div>

                {/*  */}
                <div className='relative mt-4'>
                    <Input placeholder='Confirm Password' type={type} value={confirmPass} handleChange={handleConfirmPassInput}/>
                    <i className={`${icon ? 'bx bx-show' : 'bx bx-hide'} absolute z-[1000] right-2 top-[1.6rem] cursor-pointer text-[var(--primary-color)]`} onClick={handleToggle}/>
                </div>

                {/*  */}
                <div className="flex justify-between items-center mt-6">
                  <Button type="submit" buttonText={`${isLoading ? 'Loading...' : 'Reset Password'}`} className="button" disabled={!checkMismatch()}/>
                  <Button path="/" buttonText="Go Back" className="transition ease duration-300 hover:text-[var(--primary-color)]"/>
                </div>
              </form>
            </div>
          </div>
        </div>
      )
}

export default ChangePassword