import { createSlice } from "@reduxjs/toolkit";

import Cookies from "universal-cookie";

const cookies = new Cookies();

const resetPassSlice = createSlice({
  name: "reset",
  initialState: {
    resetInfo: cookies.get("resetInfo") || null,
  },
  reducers: {
    setResetInfo: (state, action) => {
      state.resetInfo = action.payload;
      console.log(action.payload);
    },
  },
});

export const { setResetInfo } = resetPassSlice.actions;

export default resetPassSlice.reducer;

export const getResetInfo = (state) => state.reset.resetInfo;
