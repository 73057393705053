import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { useGlobalContexts } from '../../../features/contexts/GlobalContexts';


const EditButton = () => {
    const { editDashboard, setEditDashboard } = useGlobalContexts();
  return (
    <div className="relative flex items-center gap-2">
        <button 
        onClick={() => setEditDashboard(prevState => !prevState)}

        className={`rounded-sm border border-neutral-300 p-[6.2px] flex items-center justify-center cursor-pointer ${
            editDashboard
            ? 'bg-[var(--primary-color)] text-white border-transparent'
            : 'bg-white text-gray-400'
        }`}
        >
        <FontAwesomeIcon icon={faEdit}/>
        </button>

        <span className='text-xs'>Edit Dashboard</span>
    </div>
  )
}

export default EditButton