import React, { useEffect, useState, useRef } from "react";
import { DashboardLayoutComponent } from "@syncfusion/ej2-react-layouts";
import { createRoot } from "react-dom/client";
import BoxC001 from "./Widgets/BoxC001";
import Chart001 from "./Widgets/Chart001";
import { useGlobalContexts } from "../../features/contexts/GlobalContexts";
import { useSocket } from "../../features/contexts/SocketContext";
import SideButton from "./DashboardSidebar/SideButton";


const Dashboard = () => {
    const { sockets } = useSocket();
    const [dashboards, setDashboards] = useState([]);
    const [widgetData, setWidgetData] = useState([]);
    const [panels, setPanels] = useState([]);
    const { selectedSite, selectedDashboard, setAllDashboards, editDashboard, collapsed, setCollapsed, selectedTime } = useGlobalContexts();
    const cellSpacing = [5, 5];
    const layoutRef = useRef(null);
    const roots = useRef({}); // Cache for React roots
    const intervalRef = useRef(null);

    // Fetch Dashboard Data
    useEffect(() => {
        const requestDashboardData = (socket, siteId) => {
            if (!socket) return;
            const payload = {
                request: "content",
                content: { type: "dashboards", dashboards: { siteId } },
            };
            socket.emit("automation:mob:content:usr:v1", payload, (ack) => {
                if (ack?.responseStatus === "success") {
                    setDashboards(ack.data);
                    console.log(ack.data)
                    const dashboardNames = ack.data.map((dashboard) => dashboard.name);
                    setAllDashboards(dashboardNames);
                }
            });
        };

        if (sockets && selectedSite) {
            requestDashboardData(sockets.dashboard, selectedSite);
        }
    }, [sockets, selectedSite, setAllDashboards]);

    // Fetch Widget Data
    useEffect(() => {
        const requestWidgetData = async (socket, siteId) => {
            if (!socket) return;
    
            const activeDashboard = dashboards?.find((d) => d.name === selectedDashboard);
            if (!activeDashboard) return;
    
            const dashboardPayload = activeDashboard?.widgets?.map((widget) => ({
                widgetId: widget.widgetId,
                dataType: widget.dataType,
                components: widget.component.map((comp) => ({
                    componentId: comp.componentId,
                    features: comp.features.map((feature) => ({ featureId: feature.featureId })),
                })),
            }));
    
            // Wrap socket.emit in a Promise
            const emitRequest = () =>
                new Promise((resolve, reject) => {
                    socket.emit(
                        "automation:data:usr:v1",
                        { request: "dashboard", siteId: siteId, dashboard: dashboardPayload },
                        (ack) => {
                            if (ack?.responseStatus === "success") {
                                resolve(ack.data);
                            } else {
                                reject("Failed to fetch widget data");
                            }
                        }
                    );
                });
    
            try {
                const data = await emitRequest();
                console.log("Widget Data Updated: ", data);
                setWidgetData(data);
            } catch (error) {
                console.error(error);
            }
        };
    
        const startPolling = () => {
            // Clear existing interval
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
    
            // Convert minutes to milliseconds
            const intervalTime = selectedTime * 60 * 1000;
    
            // Start polling with async Promise wrapper
            intervalRef.current = setInterval(async () => {
                await requestWidgetData(sockets?.data, selectedSite);
            }, intervalTime);
        };
    
        // Check all conditions before making the initial request and starting polling
        if (sockets?.data && selectedSite && dashboards.length > 0 && selectedDashboard) {
            requestWidgetData(sockets.data, selectedSite);
        }
    
        if (sockets?.data && selectedSite && dashboards.length > 0 && selectedDashboard && selectedTime) {
            startPolling();
        }
    
        // Cleanup interval on unmount or dependency change
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [sockets, selectedSite, dashboards, selectedDashboard, selectedTime]);
    

    // Render Panels
    useEffect(() => {
        if (dashboards.length > 0 && selectedDashboard) {
            const activeDashboard = dashboards.find((d) => d.name === selectedDashboard);
            if (!activeDashboard || !activeDashboard.widgets) return;
    
            // Safeguard against undefined widgets
            const validWidgets = activeDashboard.widgets.filter((widget) => widget !== undefined);
    
            const dynamicPanels = validWidgets.map((widget, index) => ({
                id: `panel-${index + 1}`,
                sizeX: widget.widgetProperties?.layout?.sizeX || 1, // Default to 1 if undefined
                sizeY: widget.widgetProperties?.layout?.sizeY || 1,
                row: widget.widgetProperties?.layout?.row || 0,
                col: widget.widgetProperties?.layout?.col || 0,
                content: `<div id='widget-container-${index}'></div>`, // Placeholder div
            }));
    
            setPanels(dynamicPanels);

        }
    }, [dashboards, selectedDashboard]);
    

    // Dynamically render components
    useEffect(() => {
        if (panels.length > 0 && layoutRef.current) {
            panels.forEach((panel, index) => {
                const container = document.getElementById(`widget-container-${index}`);
                if (!container) return;
    
                const activeDashboard = dashboards.find((d) => d.name === selectedDashboard);
                const widget = activeDashboard?.widgets[index];
    
                // Ensure the widget exists
                if (!widget) return;
    
                // Schedule cleanup only after the current render cycle
                setTimeout(() => {
                    if (roots.current[index]) {
                        roots.current[index].unmount();
                        delete roots.current[index];
                    }
                    const root = createRoot(container);
                    roots.current[index] = root;
    
                    // Render the appropriate component
                    if (widget?.widgetId === "box-C001" || widget?.id === "box-C001") {
                        root.render(<BoxC001 widget={widget} widgetData={widgetData[index] || {}} />);
                    } else if (widget?.widgetId === "chart-001") {
                        root.render(<Chart001 widgetData={widgetData[index]}/>);
                    } else {
                        root.render(<div>No Matching Widget</div>);
                    }
                }, 0); // Defer cleanup and rendering
            });
        }
    }, [panels, widgetData, dashboards, selectedDashboard]);
    
    

    return (
        <div id="container" className="relative h-screen flex justify-between">
            <DashboardLayoutComponent
                id="defaultLayout"
                ref={layoutRef}
                cellSpacing={cellSpacing}
                panels={panels}
                allowResizing={editDashboard}
                allowDragging={editDashboard}
                columns={5}
                resizableHandles={["e-south-east", "e-east", "e-west", "e-north", "e-south"]}
                className="custom-dash-style"
            />
            <SideButton collapsed={collapsed} setCollapsed={setCollapsed} />
        </div>
    );
};

export default Dashboard;
