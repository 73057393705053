import React, { createContext, useState, useContext } from "react";

// Create the context
const Contexts = createContext();

// Create a provider component
export const ContextsProvider = ({ children }) => {
  const [selectedSite, setSelectedSite] = useState(null);
  const [selectedDashboard, setSelectedDashboard] = useState("Dashboard 1");
  const [allDashboards, setAllDashboards] = useState();
  const [editDashboard, setEditDashboard] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);

  return (
    <Contexts.Provider
      value={{
        selectedSite,
        setSelectedSite,
        selectedDashboard,
        setSelectedDashboard,
        allDashboards,
        setAllDashboards,
        editDashboard,
        setEditDashboard,
        collapsed,
        setCollapsed,
        selectedTime,
        setSelectedTime,
      }}>
      {children}
    </Contexts.Provider>
  );
};

// Custom hook for consuming the context
export const useGlobalContexts = () => {
  const context = useContext(Contexts);
  if (!context) {
    throw new Error("contexts must be used inside a context provider");
  }
  return context;
};
