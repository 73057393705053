import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import App from "./App.jsx";
import "./index.css";

import { store } from "./app/store";
import { Provider } from "react-redux";

import { HashRouter } from "react-router-dom";

import { registerLicense } from "@syncfusion/ej2-base";
import { ContextsProvider } from "./features/contexts/GlobalContexts.js";
import { SocketProvider } from "./features/contexts/SocketContext.js";

registerLicense(
  "ORg4AjUWIQA/Gnt2UlhhQlVMfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX9Rd0RjWnpacHFXQWBb"
);

createRoot(document.getElementById("root")).render(
  <StrictMode>
    <Provider store={store}>
      <HashRouter>
        <SocketProvider>
          <ContextsProvider>
            <App />
          </ContextsProvider>
        </SocketProvider>
      </HashRouter>
    </Provider>
  </StrictMode>
);
