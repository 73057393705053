import React from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import profile from '../../../../assets/userprofile.png'
import '../../../../styles/dropdown.css'

import { Dropdown } from 'antd';
import { UserOutlined, SettingOutlined, LogoutOutlined, AppstoreOutlined, NotificationOutlined } from '@ant-design/icons';

import { logOut } from '../../../../features/auth/authSlice';
import { useSocket } from '../../../../features/contexts/SocketContext';

const ProfileDropdown = ({ userData }) => {
    const { disconnectAllSockets } = useSocket(); // Use the function from the context

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userFullName = userData?.userData?.userInfo?.fullName
    const userEmail = userData?.userData?.userEmails?.[0].email

    const handleLogout = () => {
        disconnectAllSockets();
        dispatch(logOut());
        navigate('/');
    };

    const menuItems = [
        {
            key: '1',
            disabled: true,
            label: 
            (
                <div className='flex flex-col'>
                    <span className='font-semibold text-black text-xs'>{userFullName}</span>
                    <span className='text-gray-400 text-xs'>{userEmail}</span>
                </div>
            ),
            style: { cursor: 'default' }, 
        },
        {
            type: 'divider',
        },
        {
            key: '2',
            label: 'My Account',
            icon: <UserOutlined />,
        },
        {
            key: '3',
            label: 'Sites',
            icon: <AppstoreOutlined />,
        },
        {
            key: '4',
            label: 'Notifcations',
            icon: <NotificationOutlined />,
        },
        {
            key: '5',
            label: 'Settings',
            icon: <SettingOutlined />,
        },
        {
            type: 'divider',
        },
        {
            key: '6',
            label: 'Logout',
            icon: <LogoutOutlined />,
            onClick: handleLogout,
            danger: true,
        },
    ];

    const menu = {
    items: Array.isArray(menuItems) ? menuItems : [],
    };
    
    return (
        <div>
            <Dropdown menu={menu} placement="bottomLeft" trigger={['click']} overlayClassName='profile-dropdown'>
                <img className='rounded-full cursor-pointer max-w-[2.5rem]' src={profile} alt="profile" />
            </Dropdown>
        </div>
    )
}

export default ProfileDropdown