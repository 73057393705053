import { NavLink, useLocation } from "react-router-dom";
import '../styles/buttons.css'

const Button = ({ className, buttonText, path, type, handleClick, disabled}) => {
  const classes = `${className || ''}`

  const location = useLocation();
  const isActive = location.pathname === path;

  const renderButton = () => (
    <button
      type={type}  
      className={classes}
      onClick={handleClick} disabled={disabled}>
      {buttonText}
    </button>
  )
  
  const renderLink = () => (
    <NavLink 
      to={path} 
      className={`${className} ${isActive ? 'text-[var(--primary-color)]' : 'text-gray-500'}`}
    >
      {buttonText}
    </NavLink>
  )

  return path ? renderLink() : renderButton()
}

export default Button